import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { Tile } from '/features/buildingBlocks/tile/Tile'
import { useMediaQuery } from '@kaliber/use-media-query'
import { useTopOfPage } from '/machinery/useTopOfPage'
import { resolveTileLink } from '/machinery/link'
import { useLanguage } from '/machinery/I18n'

import mediaStyles from '/cssGlobal/media.css'
import styles from './TileGrid.css'

export function TileGrid({ tiles, layoutClassName, availableStyleContexts = undefined }) {
  const isMounted = useRenderOnMount()
  const topOfPage = useTopOfPage()
  const viewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false
  const language = useLanguage()

  const compactGrid = viewportMd ? topOfPage : false

  return (
    <ul className={cx(styles.component, layoutClassName)}>
      {isMounted && (
        tiles.filter(x => x.representation).slice(0, 4).map((x, i) => (
          <li
            data-context-tile-index={i}
            key={[i, x._id].join()}
            className={cx(styles.gridItem, compactGrid && styles.compact)}
          >
            <Tile
              blankTile={topOfPage && viewportMd}
              representation={x.representation}
              href={resolveTileLink({ tile: x, language })}
              {...{ availableStyleContexts }}
            />
          </li>
        ))
      )}
    </ul>
  )
}
